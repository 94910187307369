<template>
  <container-elem class="default-page">
    <sections-elem :content="$store.state.page.content.sections"></sections-elem>
  </container-elem>
</template>

<script>
export default {
  name: 'default-page'
}
</script>

<style lang="sass">
.default-page
</style>